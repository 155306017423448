import { Component, OnInit, HostListener, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IgxAccordionComponent, IgxButtonDirective, IgxRippleDirective, IgxExpansionPanelComponent, IgxExpansionPanelHeaderComponent, IgxExpansionPanelBodyComponent, IgxExpansionPanelTitleDirective, IgxTabsComponent, IgxTabItemComponent, IgxTabHeaderComponent, IgxTabHeaderLabelDirective, IgxTabContentComponent, ITabsSelectedIndexChangingEventArgs } from '@infragistics/igniteui-angular';
import Activity from 'src/app/classes/Activity/Actvity';
import { CaseBE } from 'src/app/classes/CaseBE';
import { DatalexClient, ICaseAccessLogBE, ICaseBE, ICaseContactBE } from '@datalex-software-as/datalex-client';
import { AuthGuardService } from 'src/app/services/auth-guard.service';
import { DatalexExceptionHandlerService } from 'src/app/services/datalex-exception-handler.service';
import { EventEmitterService, IForcedEventTypeEnum } from 'src/app/services/event-emitter.service';
import { ScreenDimensionService } from 'src/app/services/screen-dimension.service';
import { SystemCacheService } from 'src/app/services/system-cache.service';
import { UserRightsProviderService } from 'src/app/services/user-rights-provider.service';
import { ContactFilterProvider } from 'src/app/util/ContactFilters';
import { getJsonDateWithTimeZoneOffset, toLocalIsoString } from 'src/app/util/DatalexDateTime';
import { RoleCode } from 'src/app/util/RoleTypeEnum';
import { UserAreaEnum } from 'src/app/util/UserRightUtil';
import { ActivityLogService } from '../../UI/activity-log-overlay/activity-log.service';
import { ContactDataService } from '../contact/contact-data.service';
import { SelectedCaseComponent } from './selected-case/selected-case.component';
import { DatalexMsalService } from 'src/app/services/datalex-msal.service';
import newActivity from 'src/app/classes/Activity/Actvity';
import { CaseRolesComponent } from './case-roles/case-roles.component';
import { DocumentGridComponent } from './document/document-grid/document-grid.component';
import { CostsComponent } from './charging/costs/costs.component';
import { HoursRegistrationComponent } from './charging/hours-registration/hours-registration.component';
import { KeyFinFiguresComponent } from './key-fin-figures/key-fin-figures.component';
import { CommonModule, NgIf, NgStyle } from '@angular/common';
import { HeadingComponent } from '../../UI/heading/heading.component';
import { TitleComponent } from '../../UI/title/title.component';
import { CaseStatusEnum } from 'src/app/util/CaseStatusEnum';


@Component({
  selector: 'app-selected-case-page',
  templateUrl: './selected-case-page.component.html',
  styleUrls: ['./selected-case-page.component.scss'],
  standalone: true,
  imports: [TitleComponent, CommonModule, HeadingComponent, NgIf, IgxButtonDirective, IgxRippleDirective, NgStyle, SelectedCaseComponent, KeyFinFiguresComponent, IgxAccordionComponent, IgxExpansionPanelComponent, IgxExpansionPanelHeaderComponent, IgxExpansionPanelBodyComponent, IgxExpansionPanelTitleDirective, IgxTabsComponent, IgxTabItemComponent, IgxTabHeaderComponent, IgxTabHeaderLabelDirective, IgxTabContentComponent, CaseRolesComponent, HoursRegistrationComponent, CostsComponent, DocumentGridComponent]
})
export class SelectedCasePageComponent implements OnInit, OnDestroy {

  selectedCaseResponsibleInitials!: string;

  changeFromTimeRegistration(data: CaseBE) {
    this.initComponent(data);
  }

  caseId: string | null = '';
  caseDetails!: ICaseBE;
  caseClient!: string;
  caseResponsible!: string;
  caseTitle!: string;
  caseHeading!: string;
  expanded: boolean = true;
  lastPageOfset: number = 0;
  sharepointEnabled: boolean = false;
  startTime!: number;
  endTime!: number;
  caseAccesLogId!: string;

  caseData!: CaseBE;
  loaded = false;

  oldStartedDate!: string;
  startedDateChanged: boolean = false;
  userRightsLoaded: boolean = false;
  changeOrigin = "";

  getChargingRights!: Promise<boolean>;


  constructor(
    private dlxEx: DatalexExceptionHandlerService,
    private rights: UserRightsProviderService,
    private als: ActivityLogService,
    public screen: ScreenDimensionService,
    private authGuard: AuthGuardService,
    private router: Router,
    private route: ActivatedRoute,
    private dlxClient: DatalexClient,
    public sys: SystemCacheService,
    private cf: ContactFilterProvider,
    private eventEmitter: EventEmitterService,
    private contactData: ContactDataService,
    public dlxMsal: DatalexMsalService,
  ) {
    if (this.authGuard.IsAuthenticated() !== true) this.router.navigate(['/'])
    this.eventEmitter.getForceUpdateEvent().subscribe(() => this.initComponent());
    this.sharepointEnabled = this.sys.sharepointEnabled;


    if (this.sys.sysReady) {
      this.systemInitiation();
    } else {
      this.sys.isReady.subscribe({
        next: () => {
          this.systemInitiation();
        }
      })
    }

  }


  systemInitiation() {
    this.sharepointEnabled = this.sys.sharepointEnabled;
    this.userRightsLoaded = !!this.sys.userRights;
  }


  get hourRegistrationRights(): string {
    this.sys.userRights
    return this.sys.userRights ? this.rights.checkAccess(UserAreaEnum.HOUR_COST_REGISTRATION).accessName : "NONE";
  }
  get caseDocumentRights(): string {
    this.sys.userRights
    return this.sys.userRights ? this.rights.checkAccess(UserAreaEnum.CASE_DOCUMENTS).accessName : "NONE";
  }
  get caseContactRights(): string {
    this.sys.userRights
    return this.sys.userRights ? this.rights.checkAccess(UserAreaEnum.CONTACTS).accessName : "NONE";
  }
  get caseAccessRights(): string {
    this.sys.userRights
    return this.sys.userRights ? this.rights.checkAccess(UserAreaEnum.CASE_MANAGMENT).accessName : "NONE";
  }

  @ViewChild("accordion", {
    read: IgxAccordionComponent,
    static: false
  })
  public accordion!: IgxAccordionComponent;


  @ViewChild('selectedCase') selectedCase!: SelectedCaseComponent;
  @ViewChild('caseRolesRef') caseRolesComponent!: CaseRolesComponent;

  ngOnInit(): void {
    this.initComponent();
    this.startTime = + new Date();

  }

  ngOnDestroy(): void {

    this.endTime = + new Date();
    let s = Math.round((this.endTime - this.startTime) / 1000)
    let m = Math.max(Math.floor((s / 60) % 60), 1);
    let h = Math.floor((s / 3600) % 60);


    const accessLog: ICaseAccessLogBE = {
      CaseId: this.caseId!,
      WorkedHours: null,
      WorkedMinuttes: null,
      IsNew: true,
      Id: '00000000-0000-0000-0000-000000000000',
      CaseClient: '',
      UserId: this.sys.userId,
      OpenDate: getJsonDateWithTimeZoneOffset(new Date(this.startTime)),
      WorkingMinutes: null,
      Timestamp: [],
      EmailRef: '',
      IsDeleted: false
    };

    this.dlxClient.SaveCaseAccessLog(accessLog).subscribe({
      error: this.dlxEx.exception.bind(this)
    });

  }

  caseAccesLevel = "LOOK";

  initComponent(localData?: CaseBE) {
    this.caseId = this.route.snapshot.paramMap.get('id');
    this.sharepointEnabled = this.sys.sharepointEnabled;
    this.route.data.subscribe({
      next: (data) => {

        if (localData) {
          this.caseData = localData;
        } else {
          this.caseData = new CaseBE(data['case']);
        }
        this.selectedCaseResponsibleInitials = this.getSelectedCaseResponsibleInitials();

        let _temp = toLocalIsoString(this.caseData.getStartedDate());
        this.setHeadingValues()
        this.caseHeading = this.createCaseHeading(this.caseClient, this.caseResponsible)
        this.loaded = true;
        this.oldStartedDate = this.caseData.getStartedDate();

        let fromDate = toLocalIsoString(new Date("01.01.1970").getTime());
        let toDate = toLocalIsoString(new Date().getTime());
      },
    })

    this.caseAccesLevel = this.sys.userRights ? this.rights.checkAccess(UserAreaEnum.CASE_MANAGMENT).accessName : "NONE";
  }

  getUserRights(accessLevel: string) {
    this.getChargingRights = new Promise((resolve) => {
      if (accessLevel === "none") {
        resolve(true)
      } else {
        resolve(false)
      }
    })
  }

  private createCaseHeading(caseClient: string, caseResponsible: string): string {
    return `Klient: ${caseClient} / Saksansvarlig: ${caseResponsible}`
  }

  private setHeadingValues() {
    this.caseClient = this.caseData.getIsNew() ? this.contactData.contactName : this.cf.GetCaseRole(this.caseData.getCaseContacts(), RoleCode.KH);
    this.caseResponsible = this.caseData.getIsNew() ? this.sys.loggedinUserContact.Name : this.cf.GetCaseRole(this.caseData.getCaseContacts(), RoleCode.ISA);
  }


  public changeExpand() {
    this.expanded = !this.expanded;

  }

  public collapsePanel() {
    this.expanded = false;



  }

  public onSave() {
    if (this.isCaseRolesChangeOriging === true) {
      if (!this.mandatoryRolesExist) {
        console.error('Mandatory roles are missing. Cannot save.');
        const activity = newActivity({
          message: 'Saken mangler saksansvarlig og/eller klient hovedadressat',
          type: "warning",
          timestamp: new Date()
        });
        this.als.appendActivity(activity);
        return;
      }
    }

    this.save().then((res) => {
      this.caseData = new CaseBE(res);
    }).catch((error) => {
      console.error(error);
    });
  }


  onTabChange(e: ITabsSelectedIndexChangingEventArgs) {
    // switch (e.oldIndex) {
    //   case 0:
    //     console.log('We just left tab index:', e.oldIndex, "CaseRoles");
    //     break;
    //   case 1:
    //     console.log('We just left tab index:', e.oldIndex, "HourRegistration");
    //     break;
    //   case 2:
    //     console.log('We just left tab index:', e.oldIndex, "Documents");
    //     break;
    // }
  }


  public async save(): Promise<ICaseBE> {

    return new Promise((resolve, reject) => {
      const caseBe = this.caseData.getCase();
      this.caseRolesComponent.checkIfMandatoryRolesExist();

      this.startedDateChanged = (this.caseData.getOrigin().StartedDate !== this.caseData.getStartedDate());

      this.dlxClient.SaveCase(caseBe).subscribe({
        next: (response) => {
          if (this.startedDateChanged && this.oldStartedDate.substring(0, 10) === response.StartedDate.substring(0, 10)) {
            const activity = newActivity({
              message: 'Innkommende dato kan ikke være senere enn tidligeste aktivitet på saken.',
              type: "warning",
              timestamp: new Date()
            });
            this.als.appendActivity(activity);
            this.selectedCase.caseIncDate = this.caseData.getStartedDate() !== null ? new Date(this.caseData.getStartedDate()!).toISOString().substring(0, 10) : null;
            reject();
          } else {
            const activity = newActivity({
              message: 'Endringene er lagret',
              type: "success",
              timestamp: new Date()
            });
            this.als.appendActivity(activity);
            this.isCaseRolesChangeOriging = false;
            resolve(response);
          }
        },
        error: (error) => {
          const activity = newActivity({
            message: 'Kunne ikke lagre sak',
            type: "failure",
            timestamp: new Date()
          });
          this.als.appendActivity(activity);
          this.isCaseRolesChangeOriging = false;
          reject();
        }
      });
    });
  }


  public caseRoleChaged(event: ICaseContactBE[]) {

  }

  mandatoryRolesExist!: boolean;

  onMandatoryRolesCheck(result: boolean) {
    this.mandatoryRolesExist = result;
  }

  isCaseRolesChangeOriging: boolean = false;

  onChangeOriginCheck(result: string) {
    this.isCaseRolesChangeOriging = result.toUpperCase() === "CASEROLES" ? true : false;
  }


  isCaseClosed() {
    switch (this.caseData.getCaseStatusTypeId().toUpperCase()) {
      case CaseStatusEnum.Aktiv.toUpperCase():
        return false;
      default:
        return true;
    }
  }

  private getSelectedCaseResponsibleInitials(): string {
    const responsibleContact = this.caseData
      .getCaseContacts()
      .find(contact => contact.RoleTypeId.toUpperCase() === RoleCode.ISA.toUpperCase());

    if (responsibleContact) {
      const initials = responsibleContact.ContactInitials.trim() || this.extractInitials(responsibleContact.ContactName);
      return initials || 'UNK';
    }
    return 'UNK';
  }

  private extractInitials(fullName: string): string | null {
    if (!fullName) return null;
    const nameParts = fullName.split(' ');
    if (nameParts.length < 2) return null;

    const firstInitial = nameParts[0]?.[0]?.toUpperCase();
    const lastInitial = nameParts[nameParts.length - 1]?.[0]?.toUpperCase();
    return lastInitial && firstInitial ? `${lastInitial}${firstInitial}` : null;
  }


  iconCopied: boolean = false;

  copyCaseRefToClipboard(): void {
    if (!this.caseData) {
      console.error('Case data is not available.');
      return;
    }

    const initials = this.getSelectedCaseResponsibleInitials();
    const caseNumber = this.caseData.getOrigin().Number || 'Unknown';

    const caseRef = `(Datalex ref: ${initials}-${caseNumber})`;

    navigator.clipboard.writeText(caseRef).then(() => {
      console.log('Case reference copied to clipboard:', caseRef);
      this.iconCopied = true;
      setTimeout(() => {
        this.iconCopied = false;
      }, 2000);
    }).catch(err => {
      console.error('Failed to copy case reference to clipboard:', err);
    });
  }

}
