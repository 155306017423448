export function isMSO(fileExtention: string): boolean {
  switch (fileExtention.toLocaleLowerCase()) {
    case "doc":
    case "docx":
    case "docm":
    case "xls":
    case "xlsx":
    case "ppt":
    case "pptx":
      //case "msg":
      return true;
    default:
      return false;
  }
}

export function isMSG(fileExtention: string) {
  return fileExtention.toLowerCase() === 'msg';
}

export function getFileExt(filename: string): string {
  if (!filename) {
    return '';
  }

  let tmp = filename.split('.');
  return tmp.length > 1 ? tmp[tmp.length - 1].toLowerCase() : '';
}
