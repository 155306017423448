
import { Component, Input, OnInit, ViewChild, ChangeDetectorRef, ElementRef, OnDestroy, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { DatalexClient, ICostRegistrationBE, ICostRegistrationMaingroupBE, ICostRegistrationTypeBE, IInvoiceTypeBE, IUserLimitedBE } from '@datalex-software-as/datalex-client';
import { CaseBE } from 'src/app/classes/CaseBE';
import { SystemCacheService } from 'src/app/services/system-cache.service';

import { toLocalIsoString } from 'src/app/util/DatalexDateTime';
import { IBaseCancelableBrowserEventArgs } from '@infragistics/igniteui-angular/lib/core/utils';
import { formatDate } from 'src/app/util/FormatDate';
import { formatNumberWithThousands } from 'src/app/util/formatNumberWithThousands'
import { IgxSelectComponent, IComboFilteringOptions, IRowSelectionEventArgs, IgxNumberSummaryOperand, IgxSummaryResult, IgxGridComponent, IgxLabelDirective, IgxSimpleComboComponent, IgxComboItemDirective, IgxComboEmptyDirective, IgxComboHeaderItemDirective, IgxInputGroupComponent, IgxInputDirective, IgxDatePickerComponent, IgxIconComponent, IgxPrefixDirective, IgxSuffixDirective, IgxButtonDirective, IgxRippleDirective, IgxColumnComponent, IgxCellTemplateDirective, IgxFilterCellTemplateDirective, IgxDialogModule } from '@infragistics/igniteui-angular';
import { ComboSelectFunctionsService } from 'src/app/services/combo-select-functions.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { ScreenSizeService } from 'src/app/services/screen-size.service';
import { InvoiceTypeCode } from 'src/app/util/InvoiceTypeEnum';
import { NorwegianNumberFormatPipe } from '../../../../../util/pipes/norwegian-number-format.pipe';
import { GridFilterInputComponent } from '../../../../UI/grid-filter-input/grid-filter-input.component';
import { GridItemCountComponent } from '../../../../UI/grid-item-count/grid-item-count.component';
import { GridFooterCollectionComponent } from '../../../../UI/grid-footer-collection/grid-footer-collection.component';
import { NgStyle, NgIf } from '@angular/common';
import { DeviceService } from 'src/app/services/device.service';
import { SystemDialogEnum } from 'src/app/util/SystemDialogEnum';
import { UserRightsProviderService } from 'src/app/services/user-rights-provider.service';
import { EventEmitterService, IForcedEventTypeEnum } from 'src/app/services/event-emitter.service';
import { UserAreaEnum, UserRightTypeEnum } from 'src/app/util/UserRightUtil';
import { RoleTypes, RoleCode, RoleTypeCategoryIdEnum } from 'src/app/util/RoleTypeEnum';
import newActivity from 'src/app/classes/Activity/Actvity';
import { ActivityLogService } from 'src/app/components/UI/activity-log-overlay/activity-log.service';
import { ExcelExportButtonComponent } from "../../../../UI/excel-export-button/excel-export-button.component";
import { ButtonRowButtonComponent } from "../../../../UI/button-row-button/button-row-button.component";
import { ExportToExcelDirective } from 'src/app/directives/export-to-excel.directive';

interface IUserExtended extends IUserLimitedBE {
  filterKey: string
}

interface ICostTypeExteded extends ICostRegistrationTypeBE {
  mainTypeName: string,
  filterKey: string
}

interface IInvoiceTypeExtended extends IInvoiceTypeBE {
  filterKey: string
}

class SumSummary {
  private formatNumberLocale(number: number, locale = 'no-NO') {
    return new Intl.NumberFormat(locale, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number);
  }
  public operate(data?: any[]): IgxSummaryResult[] {
    const result = [];
    if (data) {
      let newData: number[] = [];
      data.forEach((row) => {
        newData.push(Number(row));
      });
      result.push({
        key: 'sum:',
        label: 'Sum:',
        summaryResult: this.formatNumberLocale(IgxNumberSummaryOperand.sum(data)),
      });
    }
    return result;
  }
}
class AmountSummary {
  public operate(data?: any[]): IgxSummaryResult[] {
    const result = [];
    if (data) {
      let newData: number[] = [];
      data.forEach((row) => {
        newData.push(Number(row));
      });
      result.push({
        key: 'sum:',
        label: 'Sum:',
        summaryResult: IgxNumberSummaryOperand.sum(data),
      });
    }
    return result;
  }
}

@Component({
  selector: 'app-costs',
  templateUrl: './costs.component.html',
  styleUrls: ['./costs.component.scss'],
  standalone: true,
  imports: [FormsModule, IgxDialogModule, ReactiveFormsModule, IgxLabelDirective, IgxSimpleComboComponent, IgxComboItemDirective, IgxComboEmptyDirective, IgxComboHeaderItemDirective, IgxInputGroupComponent, IgxInputDirective, IgxDatePickerComponent, IgxIconComponent, IgxPrefixDirective, IgxSuffixDirective, IgxButtonDirective, IgxRippleDirective, NgStyle, IgxGridComponent, NgIf, IgxColumnComponent, IgxCellTemplateDirective, GridFooterCollectionComponent, GridItemCountComponent, IgxFilterCellTemplateDirective, GridFilterInputComponent, NorwegianNumberFormatPipe, ExportToExcelDirective, ButtonRowButtonComponent]
})

export class CostsComponent implements OnInit, OnDestroy {
  @Output() addNewRoleCosts = new EventEmitter<{ contactId: string }>();
  @ViewChild("grid") grid!: IgxGridComponent;
  @ViewChild('roleDialog') roleDialog: any;
  public sumSummary = SumSummary;
  public amountSummary = AmountSummary;


  isMobile!: boolean;
  isTablet!: boolean;
  isDesktop!: boolean;
  private subscriptions = new Subscription();

  showBillableCosts = true;
  showNonBillableCosts = false;
  showInvoicedCosts = false;

  hasRightToEditCosts: boolean = false;
  rolePermission = 0;

  shouldUpdateDescription = true;

  constructor(private dlxClient: DatalexClient,
    public sys: SystemCacheService,
    public cdr: ChangeDetectorRef,
    public combo: ComboSelectFunctionsService,
    public screenSize: ScreenSizeService, public deviceService: DeviceService,
    public userRights: UserRightsProviderService, private eventEmitter: EventEmitterService, private als: ActivityLogService) {
    this.subscriptions.add(this.screenSize.isMobile$.subscribe(isMobile => {
      this.isMobile = isMobile;
    }));

    this.subscriptions.add(this.screenSize.isTablet$.subscribe(isTablet => {
      this.isTablet = isTablet;
    }));

    this.subscriptions.add(this.screenSize.isDesktop$.subscribe(isDesktop => {
      this.isDesktop = isDesktop;
    }));



  }

  @Input() data!: CaseBE;
  @Input() rerenderTrigger!: number;

  employees!: IUserLimitedBE[];
  employees1!: IUserLimitedBE[];
  completedBy!: string;
  completedById!: string;
  loggedInUser!: string;
  costTypes!: ICostRegistrationTypeBE[];
  costTypesDS!: ICostRegistrationTypeBE[];
  date = new Date();
  caseId!: any;
  invoiceTypes!: IInvoiceTypeBE[];
  invoiceTypeId!: string;
  selectedCostTypeDescription!: string;
  costsRegistrations!: ICostRegistrationBE[];
  selectedCostsRegistrationId!: string;
  activeMainGroups!: ICostRegistrationMaingroupBE[];

  emptyGridMessage: string = 'Ingen omkostninger registret.';
  typeMainGroupNameVisible = false;

  deleteButtonActive: boolean = false;
  cancelButtonActive: boolean = false;
  saveButtonActive: boolean = false;

  billableCosts!: ICostRegistrationBE[];
  nonBillableCosts!: ICostRegistrationBE[];
  invoicedCosts!: ICostRegistrationBE[];

  formatNumber = formatNumberWithThousands;

  newFormGroup() {
    return new FormGroup({
      completedBy: new FormControl(),
      selectedTypeId: new FormControl(),
      description: new FormControl(),
      date: new FormControl(),
      amount: new FormControl(),
      rate: new FormControl(),
      fee: new FormControl(),
      invoiceTypeId: new FormControl(),
      isVariable: new FormControl<boolean>(false),
      isNew: new FormControl<boolean>(true)
    });
  }

  costsForm = this.newFormGroup();

  doInit() {
    this.completedBy = this.sys.loggedInUser.Id;
    this.rolePermission = this.getRolePermissions();
    this.getCostRegistrations();
    this.initComponent();

  }
  ngOnInit(): void {

    try {
      this.doInit();
    } catch {
      this.sys.isReady.subscribe({
        next: () => {
          this.doInit();
        }
      })
    }

  }

  ngOnDestroy() {
    // Clean up subscriptions to prevent memory leaks
    this.subscriptions.unsubscribe();
  }

  initComponent() {

    this.loggedInUser = this.sys.loggedInUser.ContactName;
    // this.completedById = this.sys.loggedInUser.Id;
    this.employees = this.sys.allEmployees;
    this.hasRightToEditCosts = this.userRights.canEditHoursCosts();

    Object.keys(this.costsForm.controls).forEach(control => {
      if (this.hasRightToEditCosts) {
        this.costsForm.get(control)!.enable();
      } else {
        this.costsForm.get(control)!.disable();
      }
    });

    /* We create a new array EmployeesData so that the filter includes Shortnames  */

    let EmployeesData: IUserExtended[] = [];
    this.employees.forEach(employee => {
      let EmployeeExtended: IUserExtended = {
        ...employee,
        filterKey: `${employee.Shortform} ${employee.ContactName}`
      }
      EmployeesData.push(EmployeeExtended)
    })

    this.employees = EmployeesData;

    let InvoiceTypesData: IInvoiceTypeBE[] = [];
    this.invoiceTypes = this.sys.invoiceTypes;
    this.invoiceTypes.forEach(invoiceType => {
      let invoiceTypeExtended: IInvoiceTypeExtended = {
        ...invoiceType, filterKey: `${invoiceType.Code} ${invoiceType.Name}`
      }
      InvoiceTypesData.push(invoiceTypeExtended)
    })

    this.invoiceTypes = InvoiceTypesData;

    this.dlxClient.GetCostRegistrationMaingroups().subscribe({
      next: res => {
        this.activeMainGroups = res.filter(i => i.Active === true);

        this.dlxClient.GetCostRegistrationTypes().subscribe({
          next: (types) => {
            let data: ICostTypeExteded[] = [];
            types.forEach(type => {
              try {
                let maingroup = this.activeMainGroups.find(i => {
                  return i.Id === type.CostRegistrationMaingroupId
                });
                if (maingroup) {
                  let costTypeExtended: ICostTypeExteded = {
                    ...type,
                    mainTypeName: maingroup.Code === "D" ? " " + maingroup!.Name : maingroup!.Name,
                    filterKey: `${maingroup!.Name} ${type.Code} ${type.Name}`
                  }
                  data.push(costTypeExtended)
                }
              } catch (error) {
                console.warn(error)
              }
            });

            this.costTypes = data;
            this.costTypesDS = data;

          }
        })
      }
    })

    this.patchFormsDefaultValues();

    this.costsForm.valueChanges.subscribe(() => {
      this.cancelButtonActive = true;
      this.updateSaveButtonState();
      // if (this.costsForm.value.completedBy && this.costsForm.value.selectedTypeId && this.costsForm.value.description && this.costsForm.value.amount) { this.saveButtonActive = true }
    })


    this.costsForm.get('completedBy')!.valueChanges.subscribe(value => {
      this.saveButtonActive = this.costsForm.valid && !this.isCompletedByNullOrEmpty();
    });

    // this.costsForm.get('amount')!.valueChanges.subscribe(value => {
    //   if (value !== null && value !== undefined) {
    //     this.saveButtonActive = this.costsForm.valid && value !== 0;
    //     this.costsForm.patchValue({ fee: value * (this.costsForm.value.rate || 0) });
    //   }
    //   if (value === 0 || value === null || value === undefined) {
    //     this.saveButtonActive = false;
    //   }
    // });

    this.costsForm.get('amount')!.valueChanges.subscribe(value => {
      if (value !== null && value !== undefined) {
        this.costsForm.patchValue({ fee: value * (this.costsForm.value.rate || 0) });
      }
      this.updateSaveButtonState();
    });


    this.costsForm.get('rate')!.valueChanges.subscribe(value => {
      this.costsForm.patchValue({ fee: null })
      this.costsForm.patchValue({ fee: this.costsForm.value.amount * this.costsForm.value.rate })

    })

    this.costsForm.get('selectedTypeId')!.valueChanges.subscribe(value => {
      if (value && value.length === 36) {
        this.dlxClient.GetCostRegistrationType(value).subscribe({
          next: (res) => {
            const currentDescription = this.costsForm.get('description')!.value;
            // const isNew = !this.selectedCostRegistration;
            // const custom = !!currentDescription;


            const newDescription: string = res.CostRegistrationTypeDescriptions.find(d => d.Country.Code === "NO")?.Description || res.Name;

            if (this.shouldUpdateDescription) {
              this.costsForm.get('description')?.patchValue(newDescription);
            } else {
              this.costsForm.patchValue({
                description: currentDescription,
                isVariable: res.IsRateVariable
              });
            }



            if (!this.selectedCostRegistration) {
              this.costsForm.patchValue({ rate: res.Rate });
            }

            this.costsForm.patchValue({ fee: this.costsForm.value.amount * this.costsForm.value.rate });
          }
        });
      }
    });


    this.dlxClient.GetCostRegistrationTypes().subscribe({
      next: (res) => {
        this.costTypes = res;
        this.costTypesDS = res;
      }
    })



  }


  selectionChanging(event: any) {
    if (event.newSelection.value) {
      this.dlxClient.GetCostRegistrationType(event.newSelection.value).subscribe({
        next: (res) => {

          let desc = res.CostRegistrationTypeDescriptions.find(d => d.Country.Code === "NO")?.Description ?? res.Name;
          // this.costsForm.patchValue({ description: desc, rate: res.Rate })
          this.costsForm.patchValue({ fee: res.IsRateVariable ? this.costsForm.value.amount * this.costsForm.value.rate : res.Rate })
        }
      })
    }
  }

  // Save Cost Registration

  saveCost(cost: ICostRegistrationBE, type: ICostRegistrationTypeBE) {

    cost.CaseId = this.data.Id;
    cost.CompletedBy = this.costsForm.value.completedBy;
    cost.Description = this.costsForm.value.description;
    cost.Fee = this.costsForm.value.fee;
    cost.Number = this.costsForm.value.amount;
    cost.Rate = this.costsForm.value.rate;
    cost.CompletedDate = toLocalIsoString(this.costsForm.value.date);
    cost.CostRegistrationTypeId = type.Id;
    cost.InvoiceTypeId = this.costsForm.value.invoiceTypeId;

    if (cost.CostRegistrationTypeId === null) {
      console.error("CostRegistrationTypeId is null");
      throw new Error("CostRegistrationTypeId is null");
    }
    return this.dlxClient.SaveCostRegistration(cost);
  }


  onAddRoleOKSelected() {
    const selectedUserId = this.costsForm.value.completedBy;

    if (!selectedUserId) {
      console.error("Contact not selected");
      this.roleDialog.close();
      return;
    };
    const selectedContact = this.employees.find(employee => employee.Id.toUpperCase() === selectedUserId.toUpperCase())?.ContactId;
    if (!selectedContact) {
      console.error("Selected contact not found");
      this.roleDialog.close();
      return;
    }

    this.addRole(selectedContact);

    this.roleDialog.close();
  }

  @Output() notifyParent = new EventEmitter<CaseBE>();

  addRole(contactId: string) {
    this.dlxClient.NewCaseContact().subscribe({
      next: (res) => {
        res.CaseId = this.data.Id;
        res.ContactId = contactId;
        res.RoleTypeName = "Andre medarbeidere";
        res.RoleTypeCode = RoleTypes.INTERN.IDI;
        res.RoleTypeId = RoleCode.IDI;
        res.RoleTypeCategoryId = RoleTypeCategoryIdEnum.INTERN;
        res.IsNew = true;
        res.IsDeleted = false;

        const _case = JSON.parse(JSON.stringify(this.data.getCase()));
        _case.CaseContacts.push(res);

        this.dlxClient.SaveCase(_case).subscribe({
          next: (response) => {
            this.data = new CaseBE(response);
            this.notifyParent.emit(this.data)

            this.saveCostRegistration();
          },
          error: () => {
            console.error("Failed to save case contact on case");
          }
        })
      },
      error: () => {
        console.error("Failed tgo fetch new case contact");
      }
    })
  }


  getUserContact() {
    return this.employees.find(employee => employee.Id === this.costsForm.value.completedBy)?.ContactId;
  }

  validateDateWithinSevenDays(): boolean {
    const completedDateValue = this.costsForm.value.date;

    if (!completedDateValue) {
      alert('Ugyldig dato');
      return false;
    }

    const completedDate = new Date(completedDateValue);
    const today = new Date();
    const maxFutureDate = new Date(today);
    maxFutureDate.setDate(today.getDate() + 7);

    if (completedDate > maxFutureDate) {
      const activity = newActivity({
        message: 'Datoen er satt til mer enn 7 dager frem i tid.',
        type: "warning",
        timestamp: new Date()
      });

      this.als.appendActivity(activity);
      return false;
    }

    return true;
  }

  validateAmount(): boolean {
    const amount = this.costsForm.controls.amount.value;
    if (amount === 0 || amount === null) {
      alert('Validering feilet med følgende feil:\n- Antall er obligatorisk');
      return false;
    }
    return true;
  }


  onSubmit() {
    if (!this.hasRightToEditCosts) {
      alert("Du har ikke tillatelse til å registrere omkostninger.");
      return;
    }

    if (!this.validateDateWithinSevenDays()) {
      return;
    }

    if (!this.validateAmount()) {
      return;
    }
    const userHasRole = this.data.getCaseContacts().some(contact => contact.ContactId.toUpperCase() === this.getUserContact()?.toUpperCase());

    if (!userHasRole) {
      this.roleDialog.open();
    } else {
      this.saveCostRegistration();
    }
  }

  saveCostRegistration() {
    const isInvoiced = this.setSelectionIsInvoiced(this.selectedCostRegistration)
    if (isInvoiced) return;


    let selectedType = this.costTypes.find(i => i.Id === this.costsForm.value.selectedTypeId);
    if (selectedType) {
      if (this.selectedCostRegistration) { // ENDRING
        this.saveCost(this.selectedCostRegistration, selectedType).subscribe({
          next: () => {
            this.getCostRegistrations();
            this.resetCostsform();
          }
        })
      } else { //HVIS DET ER NY
        this.dlxClient.NewCostRegistration().subscribe({
          next: (res) => {
            this.saveCost(res, selectedType!).subscribe({
              next: (res) => { this.getCostRegistrations(); this.resetCostsform(); }
            })
          }
        })

      }
    }
  }

  getCostRegistrations() {
    if (!this.showBillableCosts && !this.showNonBillableCosts && !this.showInvoicedCosts) {
      this.costsRegistrations = [];
      return
    }
    this.dlxClient.GetCostRegistrationByCaseIdAndUserId(this.data.Id, null, true).subscribe({
      next: res => {
        let filteredRes = res;
        if (this.showBillableCosts || this.showNonBillableCosts || this.showInvoicedCosts) {
          filteredRes = res.filter(cost => {
            const isBillable = this.showBillableCosts && (cost.InvoiceTypeId === InvoiceTypeCode.S || cost.InvoiceTypeId === InvoiceTypeCode.J);
            const isNonBillable = this.showNonBillableCosts && (cost.InvoiceTypeId === InvoiceTypeCode.N);
            const isInvoiced = this.showInvoicedCosts && (cost.InvoiceTypeId === InvoiceTypeCode.F);
            return isBillable || isNonBillable || isInvoiced;
          });
        }
        this.costsRegistrations = filteredRes;
      }
    });
  }

  deleteCostsRegistration() {
    if (confirm(SystemDialogEnum.GENERAL_DELETE_CONFIRMATION) === false) {
      this.resetCostsform();
      return;
    }
    this.dlxClient.DeleteCostRegistration(this.selectedCostsRegistrationId).subscribe({
      next: () => {
        this.getCostRegistrations();
        this.deleteButtonActive = false;
      }
    })

    this.resetCostsform();

  }

  resetCostsform() {
    this.costsForm.reset();
    this.patchFormsDefaultValues();
    this.selectedCostRegistration = null;
    this.cancelButtonActive = false;
    this.deleteButtonActive = false;
    this.saveButtonActive = false;
    this.selectionIsInvoiced = false;
    this.grid.selectRows([], true);
    this.shouldUpdateDescription = true;
  }

  patchFormsDefaultValues() {
    // this.costsForm.patchValue({ completedBy: this.completedById, date: formatDate(new Date()), invoiceTypeId: this.invoiceTypes[0].Id, isNew: true });
    this.costsForm.patchValue({ date: formatDate(new Date()), invoiceTypeId: this.invoiceTypes[0].Id, isNew: true });
  }

  public selectedId!: string | null;

  public categoryFilter(target: EventTarget | null) {
    let value = (target as HTMLButtonElement).title;

    if (this.selectedId === value) {
      this.costTypes = this.costTypesDS;
      this.selectedId = null;
      this.typeMainGroupNameVisible = false;
      this.cdr.detectChanges();
      return;
    }
    this.selectedId = value;
    this.costTypes = this.costTypesDS.filter(type => type.CostRegistrationMaingroupId === value);
    this.cdr.detectChanges();
  }

  public filter(event: string) {
    if (event) {
      let val = event
      const mouseoverEvent = new Event('mousedown');
      document.getElementById('list')?.dispatchEvent(mouseoverEvent);

      if (this.selectedId) {
        let _temp = this.costTypesDS.filter(type => type.CostRegistrationMaingroupId === this.selectedId);
        this.costTypes = _temp.filter((item => item.Code.toUpperCase().includes(val.toUpperCase()) || item.Name.toUpperCase().includes(val.toUpperCase())));
      } else {
        this.costTypes = this.costTypesDS.filter((item => item.Code.toUpperCase().includes(val.toUpperCase()) || item.Name.toUpperCase().includes(val.toUpperCase())));
      }
    }
    this.cdr.detectChanges();
  }

  onClosing() {
    this.costTypes = this.costTypesDS;
    this.selectedId = null;
  }

  selectedCostRegistration: ICostRegistrationBE | null = null;
  handleRowSelection(event: IRowSelectionEventArgs): void {
    const selection = event.newSelection[0] as ICostRegistrationBE;
    this.shouldUpdateDescription = false;
    this.selectedCostRegistration = selection;
    this.selectedCostsRegistrationId = selection.Id;

    this.costsForm.patchValue({
      completedBy: selection.CompletedBy,
      selectedTypeId: selection.CostRegistrationTypeId,
      description: selection.Description,
      date: selection.CompletedDate,
      amount: selection.Number,
      rate: selection.Rate,
      fee: selection.Fee,
      invoiceTypeId: selection.InvoiceTypeId,
      isNew: selection.IsNew
    });

    this.selectionIsInvoiced = this.setSelectionIsInvoiced(selection);

    this.deleteButtonActive = !this.costsForm.value.isNew;
  }


  selectionIsInvoiced: boolean = false;

  setSelectionIsInvoiced(selection: ICostRegistrationBE | null) {
    if (!selection) return false;
    return selection.InvoiceTypeId.toUpperCase() === InvoiceTypeCode.F.toUpperCase()
  }


  public formatDateOptions = {
    format: 'dd.MM.yyyy',
  };

  public options = {
    digitsInfo: '.0',
    currencyCode: 'NOK',
    display: '',

  };
  public formatOptions = this.options;

  onRightClick(event: any) {
    event.preventDefault();
  }

  filterOptions: IComboFilteringOptions = {
    caseSensitive: false,
    filterable: true,
    filteringKey: "filterKey"
  }

  incrementDate() {
    let currentDate = this.costsForm.controls.date.value || new Date();
    currentDate = new Date(currentDate);
    const nextDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
    this.costsForm.controls.date.setValue(formatDate(nextDate));
  }

  decrementDate() {
    let currentDate = this.costsForm.controls.date.value || new Date();
    currentDate = new Date(currentDate);
    const previousDate = new Date(currentDate.setDate(currentDate.getDate() - 1));
    this.costsForm.controls.date.setValue(formatDate(previousDate));
  }


  getRolePermissions(): number {
    const contactAccess = this.userRights.checkAccess(UserAreaEnum.CONTACTS).accessId !== UserRightTypeEnum.NONE;
    if (contactAccess) {
      const caseAccess = this.userRights.checkAccess(UserAreaEnum.CASE_MANAGMENT);
      switch (caseAccess.accessId) {
        case UserRightTypeEnum.FULL:
          return 2;
        case UserRightTypeEnum.OWN_CASES:
          return 2;
        case UserRightTypeEnum.LOOK:
          return 1;
        default:
          return 0;
      }
    }
    return 0;
  }


  setShouldUpdateDescription(e: any) {
    if (e.value.trim() === "") {
      this.shouldUpdateDescription = true;
    } else {
      this.shouldUpdateDescription = false;
    }
  }

  private isCompletedByNullOrEmpty(): boolean {
    const completedBy = this.costsForm.get('completedBy')?.value;
    return !completedBy || completedBy.trim() === '';
  }


  private updateSaveButtonState(): void {
    const completedBy = this.costsForm.get('completedBy')?.value;
    const selectedTypeId = this.costsForm.get('selectedTypeId')?.value;
    const amount = this.costsForm.get('amount')?.value;

    this.saveButtonActive = !this.isCompletedByNullOrEmpty() &&
      selectedTypeId &&
      amount > 0;
  }

}

