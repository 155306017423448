<div class="navbar-wrapper"
  [ngStyle]="{'height': isPdfViewer ?  '0.5px' : '56px', 'display': !sys.loggedInUserAccount ? 'none' : 'block'}">
  <igx-navbar>
    <div igxNavbarAction>
      <img src="../../../../assets/images/datalex_logo.png" alt="Datalex logo" width="120px"
        (click)="navigate({route: 'shortlist', label: 'Min saksliste', icon: 'list' })">
      @if(deviceService.getDeviceType() === 'mobile') {
      <strong style="margin: 0 12px">{{activeRouteLabel}}</strong>
      }@else {
      <strong *ngIf="sys.loggedInUser" style="margin: 0 12px">{{sys.loggedInUser.AccountsName}}</strong>
      }


      <!-- <strong *ngIf="screen.width > 1024 && sys.loggedInUser"
        style="margin: 0 12px">{{sys.loggedInUser.AccountsName}}</strong> -->
    </div>

    <div class="flex-row space-between items-center">
      @if(deviceType !== 'Mobile' && sys.sharepointEnabled){

      <app-document-checkout [style]="screenWidth < 1390 ? 'justify-self: flex-start;' : 'justify-self: none;'" />
      }
      <div class="flex-row" *ngIf=" sys.loggedinUserContact" [ngClass]=" screenWidth > 1390 ? 'flex-row' : 'hide'"
        style="padding-left: 1rem;">
        <div class="navbar-item-wrapper">
          <span class="nav-item--hover" [ngClass]="activeRoute === 'shortlist' ? 'navbar-item--active' : 'navbar-item'"
            (click)="navigate({route: 'shortlist', label: 'Min saksliste', icon: 'list' })">
            <span translate="no" class="material-symbols-outlined spacer">list</span>
            <span class="navbar-item-label">Min saksliste</span>
          </span>
        </div>

        <div [ngClass]="noCaseAccess ? 'navbar-item-wrapper-no-access' : 'navbar-item-wrapper'"
          [igxToggleAction]="caseDropdown" [igxDropDownItemNavigation]="caseDropdown">
          <span class="nav-item--hover"
            [ngClass]="{'navbar-item--active': activeRoute.includes('findcase') || activeRoute.includes('case') && !activeRoute.includes('reports'),'navbar-item': true}">
            <span translate="no" class="material-symbols-outlined spacer">folder_open</span>
            <span class="navbar-item-label">Saker</span>
            <i translate="no" class="material-symbols-outlined" style="color: #000;">arrow_drop_down</i>
          </span>
        </div>


        <igx-drop-down #caseDropdown>
          <div class="card-wrapper">
            <igx-card elevated>
              <igx-card-content>
                <ul>
                  <li (click)="!noCaseAccess && navigate({route: 'findcase', label: 'Saker', icon: 'search' })"
                    (click)="caseDropdown.toggle()">
                    <span class="action-menu-button">
                      <span translate="no" class="material-symbols-outlined icon">folder_open</span>
                      <span>Finn sak</span>
                    </span>
                  </li>
                  <li [ngClass]="{'disabled': !canOpenDocumentSearch}" (click)="caseDropdown.toggle()">
                    <span class="action-menu-button" [ngClass]="{'disabled': !canOpenDocumentSearch}" dlxDocumentSearch
                      [showResults]="true">
                      <span translate="no" class="material-symbols-outlined icon">find_in_page</span>
                      <span>Dokumentsøk</span>
                    </span>
                  </li>
                </ul>
              </igx-card-content>
            </igx-card>
          </div>
        </igx-drop-down>


        <div [ngClass]="noContactAccess ? 'navbar-item-wrapper-no-access' : 'navbar-item-wrapper'">
          <span class="nav-item--hover" [ngClass]="{
            'navbar-item--active': activeRoute.includes('findcontact'),
            'navbar-item': true
          }" (click)="!noContactAccess && navigate({route: 'findcontact', label: 'Kontakter', icon: 'contacts' })">
            <span translate="no" class="material-symbols-outlined spacer">person_search</span>
            <span class="navbar-item-label">Kontakter</span>
          </span>
        </div>
        <div class="navbar-item-wrapper" [igxToggleAction]="infoDropdown" [igxDropDownItemNavigation]="infoDropdown">
          <span class="nav-item--hover"
            [ngClass]="{'navbar-item--active': activeRoute.includes('reports') || activeRoute.includes('registry-data'),'navbar-item': true}">
            <span translate="no" class="material-symbols-outlined spacer">info</span>
            <span class="navbar-item-label">Infosenter</span>
            <i translate="no" class="material-symbols-outlined" style="color: #000;">arrow_drop_down</i>
          </span>
        </div>


        <igx-drop-down #infoDropdown>
          <div class="card-wrapper">
            <igx-card elevated>
              <igx-card-content>
                <ul>
                  <li (click)="infoDropdown.toggle()">
                    <span class="action-menu-button"
                      (click)="navigate({route: 'reports/case-reports', label: 'Rapporter', icon: 'contacts' })">
                      <span translate="no" class="material-symbols-outlined icon">lab_profile</span>
                      <span>Rapporter</span>
                    </span>
                  </li>
                  <li [ngClass]="{'disabled': !canOpenRegistryEditors}" (click)="infoDropdown.toggle()">
                    <span class="action-menu-button" [ngClass]="{'disabled': !canOpenRegistryEditors}"
                      (click)="navigate({route: 'registry-data/hour-registration-registry', label: 'Registre', icon: 'contacts' })">
                      <span translate="no" class="material-symbols-outlined icon">dataset</span>
                      <span>Registre</span>
                    </span>
                  </li>
                </ul>
              </igx-card-content>
            </igx-card>
          </div>
        </igx-drop-down>
      </div>


      <div [ngClass]="screenWidth > 1390 ? 'navbar-item-wrapper' : 'hide'" [igxToggleAction]="dropdown"
        [igxDropDownItemNavigation]="dropdown" [igxToggleOutlet]="outlet" [overlaySettings]="overlaySettings">
        <span class="navbar-usermenu" *ngIf="sys.loggedinUserContact">
          <span>
            {{ sys.loggedinUserContact.FirstName + " " + sys.loggedinUserContact.LastName }}
          </span>
          <i translate="no" class="material-symbols-outlined" style="color: #000;">arrow_drop_down</i>
        </span>
      </div>

      <div class="help-section">
        <div *ngIf="screenWidth > 1390" class="seperator"></div>
        <div [ngClass]=" screenWidth > 1390 ? 'help-item-wrapper' : 'hide'"
          (click)="navigate({route: 'help/home', label: 'Hjelp', icon: 'help' })">
          <div [ngClass]="activeRoute.includes('help') ? 'help-item--active' : 'help-item'">
            <span translate="no" class="material-symbols-outlined">help</span>
          </div>
        </div>

        <div [ngClass]=" screenWidth > 1390 ? 'help-item-wrapper' : 'hide'" [igxToggleAction]="feedback"
          [igxDropDownItemNavigation]="feedback" [igxToggleOutlet]="outlet" [overlaySettings]="overlaySettings">
          <span class="help-item nav-item--hover">
            <span translate="no" class="material-symbols-outlined spacer">forward_to_inbox</span>
          </span>
        </div>

        <!-- <div class="navbar-item-wrapper" *ngIf="screen.width > 1390 && !sys.loggedinUserContact">
              <span class="navbar-item" (click)="logOut()">
                <strong>Logg inn</strong>
              </span>
            </div> -->



      </div>
    </div>

    <div class="overlayOutlet" igxOverlayOutlet #outlet="overlay-outlet"></div>
  </igx-navbar>

  <!-- <app-breadcrumb *ngIf="screen.width > 1024"></app-breadcrumb> -->

</div>

<igx-drop-down #feedback>
  <div class="card-wrapper">
    <igx-card elevated>
      <igx-card-header>
        <strong igxCardHeaderTitle style="margin-bottom: 1rem;">Tilbakemeldinger</strong>
        <h3 igxCardHeaderSubtitle style="margin-bottom: 1rem;">Vi ønsker deres tilbakemeldinger i forbindelse med
          bruk av DatalexWeb.</h3>
        <h3 igxCardHeaderSubtitle>Trykk på knappene under for å sende oss en henvendelse hvis dere opplever feil,
          eller hvis dere har andre innspill, forbedringsforslag eller lignende</h3>
      </igx-card-header>

      <igx-card-content>

        <ul>
          <li>
            <a class="action-menu-button" href="mailto:support@datalexsoftware.no?subject=DatalexWeb:%20Tilbakemelding">
              <span translate="no" class="material-symbols-outlined icon">add_reaction</span>
              <span>Gi tilakemelding</span>
            </a>
          </li>
          <li>
            <a class="action-menu-button" href="mailto:support@datalexsoftware.no?subject=DatalexWeb:%20Feil%20funnet">
              <span translate="no" class="material-symbols-outlined icon">bug_report</span>
              <span>Rapporter feil</span>
            </a>
          </li>
        </ul>

      </igx-card-content>
    </igx-card>
  </div>
</igx-drop-down>



<igx-drop-down #dropdown>
  <div class="card-wrapper">
    <igx-card elevated>
      <igx-card-header *ngIf="sys.loggedinUserContact">
        <h3 igxCardHeaderTitle>{{ sys.loggedinUserContact.FirstName + " " +
          sys.loggedinUserContact.LastName
          }}</h3>
        <h5 igxCardHeaderSubtitle>{{ sys.loggedinUserContact.Title }}</h5>
        <h5 igxCardHeaderSubtitle style="margin-top:  5px;">Versjon: {{appV}}</h5>
      </igx-card-header>

      <igx-card-content>


        <app-document-checkout *ngIf="screen.width < 1023 && sys.sharepointEnabled"></app-document-checkout>
        <div>

          @if(env.production === false) {
          <app-menu-item [label]="'Innstillinger'" [icon]="'close'" (click)="mNav('settings')"></app-menu-item>
          }

          @if (sys.isMaster) {
          <app-menu-item *ngIf="" [label]="'Kunder'" [icon]="'close'" [igxToggleAction]="dropdown"
            [igxToggleOutlet]="outlet" (click)="mNav('customers')"></app-menu-item>
          }

          @if(sys.microsoftGraphClientConfig) {

          @if (!isLoggedInSharepoint) {
          <app-menu-item [label]="'koble til Microsoft'" [icon]="'connect'" (click)="initMsalAuth()"></app-menu-item>
          } @else {
          <app-menu-item [label]="'Deaktiver dokumenttilkobling'" [icon]="'disconnect'"
            (click)="maslLogout()"></app-menu-item>
          }
          }
          <app-menu-item [label]="'Logg ut'" [icon]="'logout'" [igxToggleAction]="dropdown" [igxToggleOutlet]="outlet"
            (click)="logOut()"></app-menu-item>
        </div>
      </igx-card-content>
    </igx-card>
  </div>
</igx-drop-down>

<button *ngIf="sys.loggedinUserContact" [ngClass]="isPdfViewer ? 'hide' : ''"
  [ngClass]="screenWidth > 1390 ? 'hide' : 'burger-menu'" (click)="toggleMenu(); this.drawer.toggle();">
  <span [ngClass]="{ 'burger-menu__bar': true, open: showMenu }"></span>
  <span [ngClass]="{ 'burger-menu__bar': true, open: showMenu }"></span>
  <span [ngClass]="{ 'burger-menu__bar': true, open: showMenu }"></span>

  @if( deviceType === 'Mobile'){<igx-badge *ngIf="showBadge && dcs.getDocuments().length> 0"
    [style]="'background: #ED1717 ; color: white'">{{dcs.getDocuments().length}}</igx-badge>
  }
</button>

<igx-nav-drawer [ngClass]="isPdfViewer ? 'hide' : ''" id="navigation" #drawer [isOpen]="false"
  [ngClass]=" screenWidth > 1390 ? 'hide' : 'nav-drawer'" [pinThreshold]="5000" [position]="'right'"
  [width]="screenWidth < 580 ? '95%' : '60%'" (closing)="closeMenu()" (opening)="openMenu()" [enableGestures]="false">
  <ng-template igxDrawer>
    <nav class="drawer-nav">
      @if(sys.loggedinUserContact && sys.loggedInUser){
      <div class="userText">
        <strong class="m-top">{{sys.loggedInUser.AccountsName}}</strong>
        <div>{{sys.loggedinUserContact.FirstName + " " + sys.loggedinUserContact.LastName}}</div>
        <div>{{sys.loggedinUserContact.Title}}</div>
      </div>
      }

      @if(sys.sharepointEnabled && deviceType === 'Mobile') {
      <app-document-checkout />
      }

      @if(env.production === false && isHostDev(href)) {
      <span igxDrawerItem [active]="activeRouteLabel === 'test'" igxRipple
        (click)="navigate({route: 'test/inbox', label: 'Test', icon: 'science' })">
        <span translate="no" class="material-symbols-outlined spacer">science</span>
        <span class="navbar-item-label">Test</span>
      </span>
      }


      <!-- <span *ngFor="let item of navigationProperties" igxDrawerItem [active]="item.label === activeRouteLabel" igxRipple
        (click)="navigate(item)">
        <span translate="no" class="material-symbols-outlined spacer">{{ item.icon }}</span>
        <span>{{ item.label }}</span>
      </span> -->

      <span *ngFor="let item of navigationProperties" igxDrawerItem [active]="item.label === activeRouteLabel" igxRipple
        [ngClass]="{ 'disabled': (item.route === 'findcontact' && noContactAccess) }"
        (click)="(item.route === 'findcontact' && noContactAccess) ? $event.stopPropagation() : navigate(item)">
        <span translate="no" class="material-symbols-outlined spacer">{{ item.icon }}</span>
        <span>{{ item.label }}</span>
      </span>


      @if(deviceType === 'Desktop') {
      <span igxDrawerItem [ngClass]="{'disabled': !canOpenDocumentSearch}" dlxDocumentSearch [showResults]="true"
        (click)="drawer.close()">
        <span translate="no" class="material-symbols-outlined spacer">find_in_page</span>
        <span>Dokumentsøk</span>
      </span>

      <span igxDrawerItem (click)="navigate({route: 'reports/case-reports', label: 'Rapporter', icon: 'contacts' })">
        <span translate="no" class="material-symbols-outlined spacer">lab_profile</span>
        <span>Rapporter</span>
      </span>
      <span igxDrawerItem [ngClass]="{'disabled': !canOpenRegistryEditors}"
        (click)="navigate({route: 'registry-data/hour-registration-registry', label: 'Registre', icon: 'contacts' })">
        <span translate="no" class="material-symbols-outlined spacer">dataset</span>
        <span>Registre</span>
      </span>
      }

      <span igxDrawerItem (click)="navigate({route: 'help/home', label: 'Hjelp', icon: 'help', })">
        <span translate="no" class="material-symbols-outlined spacer">help</span>
        <span>Hjelp</span>
      </span>


      @if(sys.microsoftGraphClientConfig){ @if (!isLoggedInSharepoint) {
      <span igxDrawerItem [active]="activeRouteLabel === 'test'" igxRipple (click)="initMsalAuth()">
        <span translate="no" class="material-symbols-outlined spacer">link</span>
        <span class="navbar-item-label">koble til Microsoft</span>
      </span>
      } @else {
      <span igxDrawerItem [active]="activeRouteLabel === 'test'" igxRipple (click)="maslLogout()">
        <span translate="no" class="material-symbols-outlined spacer">link_off</span>
        <span class="navbar-item-label">koble fra Microsoft</span>
      </span>
      }}

      <span igxDrawerItem igxRipple
        (click)="navigate( { route: '', label: 'Logg ut', icon: 'logout', drawerOnly: true })">
        <span translate="no" class="material-symbols-outlined spacer">logout</span>
        <span>Logg ut</span>
      </span>
    </nav>
  </ng-template>
</igx-nav-drawer>