import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { DatalexExceptionHandlerService } from 'src/app/services/datalex-exception-handler.service';
import { SystemCacheService } from 'src/app/services/system-cache.service';
import { CookieProvider } from 'src/app/util/Cookies';
import { environment } from 'src/environments/environment';
import { DatalexClient, DatalexAuth } from '@datalex-software-as/datalex-client'
import { DatalexMsalService } from 'src/app/services/datalex-msal.service';
import { InformationOverlayService } from '../../UI/info-overlay/information-overlay.service';
import { IgxButtonDirective, IgxDialogComponent, IgxDialogModule, IgxIconModule, IgxSnackbarComponent } from '@infragistics/igniteui-angular';
import { OTPInputComponent } from '../../UI/otpinput/otpinput.component';
import { FormErrorMessageComponent } from '../../UI/form-error-message/form-error-message.component';
import { FormsModule } from '@angular/forms';
import { NgIf, NgStyle, NgTemplateOutlet } from '@angular/common';
import { TaskSchedulerService } from 'src/app/services/task-scheduler.service';
import { CountryService } from 'src/app/services/country.service';
import { firstValueFrom, Subscription } from 'rxjs';
import { FirebaseService } from 'src/app/services/firebase.service';
import { ActivityLogService } from '../../UI/activity-log-overlay/activity-log.service';

import newActivity from 'src/app/classes/Activity/Actvity';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  standalone: true,
  imports: [NgIf, IgxDialogModule, IgxIconModule, IgxButtonDirective, FormsModule, FormErrorMessageComponent, OTPInputComponent, NgStyle, NgTemplateOutlet]
})
export class LoginComponent implements OnInit {

  @ViewChild(IgxSnackbarComponent, { static: true }) public snackbar!: IgxSnackbarComponent;
  @ViewChild("otpInput") private otpComponent!: OTPInputComponent;

  @ViewChild('versionDialog', { static: true }) versionDialog!: IgxDialogComponent;

  dialogTitle: string = '';

  dialogMessage: string = '';
  isMandatoryUpdate: boolean = false;
  private skippedOptionalUpdate: boolean = false;
  isUpdated: boolean | null = true;
  serverOutdated: boolean = false;


  username!: string;
  password!: string;
  loginLoading: boolean = false;
  errMessage: boolean = false;
  twoFactor: boolean = false;
  maxRetry!: number;
  codeLength!: number;
  tries: number = 0;
  mfacode!: string;
  mfaError: boolean = false;
  userId!: string;
  firstVisit: boolean = true;
  displayCookieWarning: boolean = true;
  action: string = "";
  subId = "";

  activeRoute: string = '';
  isLoginPage: boolean = false;

  year = new Date().getFullYear();

  private routerSubscription: Subscription;

  constructor(
    private activatedRoute: ActivatedRoute,
    private system: SystemCacheService,
    private cookie: CookieProvider,
    private router: Router,
    @Inject(DatalexClient) private dlxClient: DatalexClient,
    @Inject(DatalexAuth) private dlxAuth: DatalexAuth,
    private dlxEx: DatalexExceptionHandlerService,
    private dlxMsal: DatalexMsalService,
    private info: InformationOverlayService,
    private taskScheduler: TaskSchedulerService,
    private fb: FirebaseService,
    private countryService: CountryService,
    private als: ActivityLogService,
  ) {

    this.firstVisit = !this.cookie.get("firstVisit");
    this.displayCookieWarning = !this.cookie.get("cookieWarning");

    this.activatedRoute.queryParams.subscribe(params => {
      let user = params['username'];
      let action = params['action'];
      if (user) this.username = user;
      if (action) this.action = action;

    });

    this.routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.activeRoute = event.urlAfterRedirects.startsWith('/') ? event.urlAfterRedirects.substring(1) : event.urlAfterRedirects;

        if (event.urlAfterRedirects === '/' || event.urlAfterRedirects.includes('action=logout')) {
          this.isLoginPage = true;
        } else {
          this.isLoginPage = false;
        }
      }
    });
  }

  ngOnInit(): void {
    this.fb.checkAppVersion().then((res) => {
      this.isUpdated = res;

    });

    this.fb.checkAppVersion().then((res) => {
      this.isUpdated = res;

      if (res === false) {
        const activity = newActivity({
          message: `En ny versjon av applikasjonen er tilgjengelig. Vennligst oppdater siden manuelt (Ctrl + F5).`,
          type: "warning",
          timestamp: new Date(),
          userDismiss: true

        });
        this.als.appendActivity(activity);
      } else if (res === null) {
        console.log('Kunne ikke verifisere applikasjonsversjonen.');
      } else {
        console.log('Applikasjonen er oppdatert til den nyeste versjonen.');
      }
    });

    if (this.action === "logout") {
      this.taskScheduler.stop();
      this.system.clearCache();
      this.dlxClient.token = this.dlxClient.webURL = "";
      this.cookie.delete(["token", "weburl", "userid"]);
    }
    let el = document.getElementById('appVersion');
    el!.textContent! = "Versjon: " + environment.appVersion;
  }

  errorHandler = (error: HttpErrorResponse) => {
    this.errMessage = true;
    this.loginLoading = false;
  }

  openCookiePolicy() {
    alert("Cookie policy");
  }

  getRandomId() {
    if (crypto && typeof crypto.randomUUID === 'function') {
      return crypto.randomUUID();
  } else {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = Math.random() * 16 | 0;
      const v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
  });
  }
  }

  newAuth() {
    
    this.loginLoading = true;
    this.errMessage = false;
    const _sessionFromCookie = this.cookie.get("sessionId")
    const sessionId = _sessionFromCookie && _sessionFromCookie !== "" ? _sessionFromCookie : this.getRandomId();
    if (!_sessionFromCookie) {
      this.cookie.set("sessionId", sessionId);
    }

    try {

      this.dlxAuth.getServices(this.username, sessionId).subscribe({
        next: (r1) => {
          
          if (r1.DatalexWebURL === null) {
            console.error("DatalexREST interface not installed on server")
          }
          this.cookie.set('weburl', r1.DatalexWebURL);
          this.dlxAuth.authenticate(r1.Username, this.password, r1.DatalexWebURL, sessionId).subscribe({
            next: (r2) => {
              
              this.dlxClient.initializeClient(r2.Token, r1.DatalexWebURL, sessionId)
              this.userId = r2.UserId;
              this.cookie.setToken(r2.Token);
              this.twoFactor = r2.TwoFactor
              this.maxRetry = r2.MaxRetry
              this.codeLength = r2.SizeOfCode ?? 4;
              this.twoFactor = r2.TwoFactor;
              if (r2.TwoFactor) {
                this.loginLoading = false;
                if (this.username.includes(".")) {
                  this.subId = this.username.split('.')[1].split('@')[0];
                }
                this.mfaRoutine(this.userId, this.subId)
                return
              } else {
                this.validateServerVersion();
              }

            },
            error: this.errorHandler.bind(this)
          })
        },
        error: this.errorHandler.bind(this)
      })
    }
    catch (error: any) {
      this.errorHandler(error)
    }
  }

  mfaRoutine(userId: string, subId: string = this.subId) {
    console.log('sending mfa sms');
    this.otpCatcher()
    this.dlxClient.Send2FactorSMS(userId, subId).subscribe({
      next: (res) => {
        document.getElementById('mfa-input')?.focus();
      }
    })
  }

  returnToLogin() {
    this.loginLoading = false;
    this.errMessage = false;
    this.twoFactor = false;
  }

  otpCatcher() {
    if ('OTPCredential' in window) {
      const input = document.getElementById('mfa-input')
      if (!input) return;
      const ac = new AbortController();

      try {
        let reqObj = {
          otp: { transport: ['sms'] },
          signal: ac.signal
        };
        navigator.credentials.get(reqObj).then((otp: any) => {
          if (otp) {
            if (otp && otp.code) {
              this.mfacode = otp.code;
            }
          }
        }).catch(err => {
          console.log(err);
        });
      } catch (error) {
        console.error(error)
        ac.abort();
      }
    } else {
      console.error('no OTPCredential in window');
    }
  }

  async validateServerVersion() {
    try {
      const { CurrentServerVersion } = await firstValueFrom(this.dlxClient.GetSystemSettings());

      if (CurrentServerVersion) {
        this.fb.controlServerCompability(CurrentServerVersion).subscribe({
          next: (isOutdated) => {
            
            console.log('DatalexWeb is up-to-date.', isOutdated);
            this.newLogin();
          },
          error: (error) => {
            console.error('Incompatible server version:', error);
            this.twoFactor = false;
            this.serverOutdated = true;
            this.loginLoading = false;
          }
        });
      } else {
        console.warn('Unable to retrieve CurrentServerVersion.');
        this.newLogin(); // Proceed without version check
      }
    } catch (error) {
      console.error('Error during server version validation:', error);
      this.forceRefresh(); // Fallback to forcing a refresh
    }
  }


  onDialogCancel(): void {
    if (this.isMandatoryUpdate) {
      // Mandatory update: Prevent login
      console.warn('Login canceled due to mandatory update requirement.');
      this.resetLoginState(); // Reset the login state as the process is aborted
    } else {
      // Optional update: Proceed with login
      this.skippedOptionalUpdate = true;
      this.versionDialog.close();
      // this.newAuth();
      this.newLogin();
    }
  }


  private resetLoginState(): void {
    this.loginLoading = false;
    this.errMessage = false;
    this.username = '';
    this.password = '';
    this.twoFactor = false;
  }


  async newLogin() {
    
    this.dlxClient.GetHourRegistrationTypes().subscribe({
      next: (r1) => {
        this.system.setHourRegistrationMainGroups(r1);

        this.dlxClient.GetInvoiceTypes().subscribe({
          next: (r2) => {
            this.system.setInvoiceTypes(r2);

            this.dlxClient.GetRoleTypeCategorys().subscribe({
              next: (r3) => {
                this.system.setRoleTypeCatergorys(r3);

                this.dlxClient.GetRoleTypes().subscribe({
                  next: (r4) => {
                    this.system.setRoleTypes(r4);

                    this.dlxClient.GetDisciplines().subscribe({
                      next: (r5) => {
                        this.system.setDiciplines(r5);

                        this.dlxClient.DocumentSharePointEnabled().subscribe({
                          next: (r6) => {
                            this.system.setSharepointEnabled(r6);
                          }, error: this.dlxEx.exception.bind(this)
                        });

                        this.dlxClient.GetDocumentTypes().subscribe({
                          next: (r7) => {
                            this.system.setDocumentTypes(r7);

                            this.dlxClient.GetDocumentCategorysByIndex(null).subscribe({
                              next: (r8) => {
                                this.system.setDocumentCategories(r8);

                                this.dlxClient.FindUsersLimited("", false).subscribe({
                                  next: (r9) => {
                                    this.system.setAllUsers(r9);

                                    this.dlxClient.ValidateLogin().subscribe({
                                      next: (r10) => {
                                        this.countryService.initCountryService();
                                        this.system.setUserId(r10);
                                        this.cookie.set('userid', r10);

                                        this.dlxClient.GetUserLimitedById(r10).subscribe({
                                          next: (r11) => {
                                            this.system.setLoggedInUser(r11);

                                            this.dlxClient.GetContactLimitedById(r11.ContactId).subscribe({
                                              next: (r12) => {
                                                this.system.setLoggedinUserContact(r12)

                                                this.dlxClient.GetUserPictureByUserId(r11.Id).subscribe({
                                                  next: (r13) => {
                                                    this.system.setProfilePicture(r13)

                                                    this.dlxClient.GetAccounts(r12.AccountsId!).subscribe({
                                                      next: (r14) => {

                                                        this.dlxClient.GetUser(r11.Id).subscribe({
                                                          next: (r15) => {

                                                            this.dlxClient.ValidateMaster().subscribe({
                                                              next: (r16) => {
                                                                this.system.setIsMaster(r16);

                                                                this.system.setUserRights(r15.UserRights);
                                                                this.system.setLoggedInUserAccount(r14)

                                                                this.loginLoading = false;
                                                                this.cookie.set("firstVisit", "false");

                                                                this.dlxMsal.getAppSettings()
                                                                  .then((res) => {
                                                                    let settings = this.dlxMsal.unpackAzureSettings(res);
                                                                    if (settings) {
                                                                      this.system.setMicrosoftGraphClientConfig(settings)
                                                                      this.dlxMsal.initialize({ userInitiated: false, config: settings });
                                                                    }
                                                                  })
                                                                  .catch((err) => {
                                                                    this.info.addMessage({
                                                                      type: 1,
                                                                      id: "GraphConfigMissing",
                                                                      message: "Datalex er ikke integrert med SharePoint, Kontakt Datalex support for mer informasjon.",
                                                                      sender: "Login"
                                                                    })
                                                                  })

                                                                this.taskScheduler.start();
                                                                this.system.ready();

                                                                this.router.navigate(['/shortlist']);
                                                                this.loginLoading = false;
                                                              }
                                                            })
                                                          }
                                                        })
                                                      }, error: this.dlxEx.exception.bind(this)
                                                    })
                                                  }, error: this.dlxEx.exception.bind(this)
                                                })
                                              }, error: this.dlxEx.exception.bind(this)
                                            })
                                          }, error: this.dlxEx.exception.bind(this)
                                        })
                                      }, error: this.dlxEx.exception.bind(this)
                                    });
                                  }, error: this.dlxEx.exception.bind(this)
                                })
                              }, error: this.dlxEx.exception.bind(this)
                            });
                          }, error: this.dlxEx.exception.bind(this)
                        });
                      }, error: this.dlxEx.exception.bind(this)
                    });
                  }, error: this.dlxEx.exception.bind(this)
                });
              }, error: this.dlxEx.exception.bind(this)
            });
          }, error: this.dlxEx.exception.bind(this)
        });
      }, error: this.dlxEx.exception.bind(this)
    });



  }

  onSubmit() {
    
    this.newAuth();
  }



  onOtpComplete(e: string) {
    this.mfacode = e
    this.onMfaSubmit();
  }


  onMfaSubmit() {
    this.loginLoading = true;
    this.mfaError = false;


    this.dlxClient.Validate2FactorCode(this.mfacode).subscribe({
      next: (res) => {
        if (res) {
          this.validateServerVersion();
        } else {
          setTimeout(() => {
            this.tries++;
            this.loginLoading = false;
            this.mfaError = true;
            this.mfacode = "";
            if (this.otpComponent) {
              this.otpComponent.resetOtp();
              this.otpComponent.setErrorState(true);
            }
          }, 1000)
        }
      },
      error: (error) => {

      }
    })
  }

  sendNewMfa() {
    this.mfaError = false;
    this.dlxClient.Send2FactorSMS(this.userId, "").subscribe({
      next: (res) => {
        this.tries = 0;
      }
    })
  }

  foreceRefreshEnabled: number = 0;
  enableFroceRefresh() {
    if (this.foreceRefreshEnabled === 3) {
      this.foreceRefreshEnabled = 0;
    }
    this.foreceRefreshEnabled++;
  }

  refreshApp() {
    window.location.reload(); // Force a full-page reload to fetch the latest version.
  }

  forceRefresh() {
    window.location.href = window.location.href + "?reload"; // Ensure full reload with potential cache bypass.
  }


}